.pending-spinner {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &.simple {
    height: 100%;
    width: 100%;
  }

  .title {
    @include XLMedium;
    margin-top: var(--20px);
  }

  .ps-mid {
    align-self: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include LMedium;

    .spinner {
      margin-bottom: var(--40px);
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: conic-gradient(#1d41ff, #1c3cf500, #1d41ff);
      mask: linear-gradient(#fff 0 0) padding-box,
        radial-gradient(circle closest-side, transparent 100%, #000 0);
      animation: spin 1s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
    }

    .spinner::before {
      content: '';
      position: absolute;
      top: 5%;
      left: 5%;
      width: 90%;
      height: 90%;
      background: white;
      border-radius: 50%;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
