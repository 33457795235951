.activity {
  display: flex;
  flex-direction: column;
  padding: 0 var(--24px);

  h1 {
    @include XL2Medium;
    margin-top: var(--24px);
    margin-bottom: var(--40px);
  }

  .filters-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: var(--24px);
    margin-top: var(--24px);
    height: var(--40px);

    &.no-top {
      margin-top: 0;
    }

    .cooldown {
      @include SRegular;
      color: var(--black-alpha-50);
      margin-right: var(--16px);
    }
  }

  .act-con {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    min-height: var(--600px);

    &.not-loaded {
      background-color: var(--black-alpha-02);
      border-radius: 2px;
      border: 1px dashed var(--black-alpha-10);
      margin-top: var(--40px);
    }

    .cluster {
      display: flex;
      flex-direction: column;
      align-self: center;
      text-align: center;

      img {
        margin-bottom: var(--40px);
      }

      h3 {
        @include XLMedium;
        margin-bottom: var(--16px);
      }

      h5 {
        @include MRegular;
        color: var(--black-alpha-50);
      }
    }
  }

  .act {
    min-height: 27.75rem;
  }
  .act-columns {
    display: flex;
    align-items: center;
    padding: var(--10px) 0;
    width: 100%;
    justify-content: space-evenly;

    .act-column {
      flex: 1;
      @include SRegular;

      .heading {
        color: var(--black-alpha-50);
      }

      &.with-icon {
        display: flex;
        align-items: center;
        gap: var(--16px);
      }

      &.grey {
        color: var(--black-alpha-50);
      }

      a {
        color: var(--sp-primary);
        cursor: pointer;
      }

      .last-link {
        float: right;
      }
    }
  }
  .act-under {
    P {
      @include SRegular;
    }
  }
}

.dropdowns {
  display: flex;
  align-items: center;
  gap: var(--16px);
}

.custom-dropdown {
  position: relative;
  display: flex;
  width: fit-content;
  align-items: center;
  border: 1px solid var(--black-alpha-85);
  padding: var(--4_5px) var(--5_5px);
  @include SMedium;
  cursor: pointer;
  border-radius: var(--2px);

  &:hover {
    background-color: var(--black-alpha-03);
  }

  &.is-open {
    background-color: var(--black-alpha-03);
  }

  .dropdown-selected {
    display: flex;
    align-items: center;
  }

  .dropdown-options {
    position: absolute;
    top: 120%;
    // right: 2.5%;
    right: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    border: 1px solid var(--black-alpha-10);
    border-top: none; // Remove the top border to blend with the selected area
    background-color: var(--white);
    max-height: 200px;
    overflow-y: auto; // Add scroll for many options
    z-index: 1000; // Ensure dropdown appears above other content
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.15);
    border-radius: var(--2px);
    border: 1px solid var(--black-alpha-10);

    li {
      padding: 8px;
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: var(--black-alpha-05);
        color: var(--sp-primary);
      }
    }
  }
}

.pagination-controls {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--16px);
  @include SMedium;
  margin-top: var(--16px);

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--32px);
    height: var(--32px);
    border-radius: 9999rem;
    padding: var(--5_5px) 0 var(--6_5px) 0;
    background-color: var(--black-alpha-03);
  }

  button.selected {
    background-color: var(--black-alpha-85);
    color: var(--white);
  }
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-270 {
  transform: rotate(270deg);
}
