.sp-custom-column {
  width: 100%;
  height: 100%;
  padding: var(--15px) var(--20px);
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px var(--10px) var(--40px) rgba(0, 0, 0, 0.1);

  .title-box {
    @include XLMedium;
    .title {
      color: var(--sp-primary);
    }
    &.left-title {
      align-self: baseline;
    }
    .comp {
      color: var(--black-alpha-85);
    }
  }
}

.container {
  background-color: var(--white);
  padding: var(--20px);
  border-radius: var(--2px);
  height: 100%;
  box-shadow: 0px var(--10px) var(--40px) var(--black-alpha-10);

  .split {
    display: flex;
    justify-content: space-between;
  }
}

.nav-wrapper {
  width: 100%;

  border-bottom: 1px solid transparent;

  &.divide {
    border-bottom: 1px solid var(--black-alpha-10);
  }

  .top-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--70px);
    background-color: var(--white);
    width: 100%;
    max-width: var(--1488px);
    margin: 0 auto;
    padding: 0 var(--24px);

    .left-logo {
      display: flex;
      align-items: center;
      gap: var(--4px);
      .beta-tag {
        background-color: var(--black);
        border-radius: 100px;
        height: var(--14px);
        padding: 0 4px 0 5px;
        color: var(--white);
        width: fit-content;
        align-self: baseline;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: var(--14px);
      }
    }

    .piece {
      flex: 1;
    }

    .mid-links {
      display: flex;
      align-items: center;
      gap: var(--16px);
      justify-content: center;

      @include mobile-style {
        display: none;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: flex-end;
    }

    .money-dropdown {
      position: relative;
      display: inline-block;
      margin-right: var(--16px);

      .money-dropdown-toggle {
        cursor: pointer;
      }

      .money-dropdown-menu {
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: #f9f9f9;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;

        button {
          color: black;
          padding: 12px;
          text-decoration: none;
          display: block;
          text-align: left;
          border: none;
          background: none;
          width: 100%;

          &:hover {
            background-color: #f1f1f1;
          }
        }
      }
    }
  }
}

.dashboard {
  padding: var(--0px) var(--24px);

  @include mobile-style {
    padding: 0;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .inner-dash {
    margin-top: var(--25px);
    width: 100%;
    display: flex;
    gap: var(--24px);
    flex-grow: 1;
    min-height: 40rem;

    .d-left {
      flex-grow: 1;
    }
  }

  .d-right {
    width: var(--352px);
    @include mobile-style {
      margin-top: var(--24px);
      width: 100%;
    }
  }
}

.section {
  padding: 0;
  max-width: var(--1488px);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 32px;
  min-height: 86vh;

  @include mobile-style {
    max-width: 100vw;
  }

  &.no-height {
    min-height: fit-content;
    height: fit-content;
    margin-bottom: 0px;
  }

  // height:
}

.section-1200 {
  padding: 0;
  max-width: var(--1200px);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 32px;
}

.section-wrapper {
  display: flex;
  justify-content: center;
  height: fit-content;
}

.columns {
  margin: 0 !important;
  &:not(:last-child) {
    margin-bottom: 0;
  }

  .column {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.tab-bar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: var(--16px);
}

.banner {
  height: 48px;
  min-height: 48px;
  width: 100%;
  background-color: var(--sp-primary);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--24px) 0 var(--24px);

  .left {
    display: flex;
    align-items: center;
  }

  .middle {
    @include MMedium;
    display: flex;
    align-items: center;
    gap: var(--24px);
  }

  .right {
    display: flex;
    align-items: center;
    padding: var(--8px);

    img {
      cursor: pointer;
    }
  }
}

.footer {
  display: flex;
  flex-grow: 1;
  gap: var(--24px);
  @include MMedium;
  align-items: center;
  margin-bottom: var(--24px);
  margin-left: var(--24px);

  @include mobile-style {
    // display: none;
    position: relative;
    z-index: 2;
    bottom: 0;
    left: 0;
    background-color: var(--white);
    height: 9vh;
    width: 100%;
    margin-left: 0;
    padding: 0 var(--24px);
    margin-bottom: 0;
  }

  .net-switch {
    display: flex;
    align-items: center;
    gap: var(--8px);
    cursor: pointer;
    @include SRegular;
    color: var(--black-alpha-50);
  }

  .footer-links {
    @include mobile-style {
      display: none;
    }
    display: flex;
    align-items: center;
    gap: var(--24px);
    @include SMedium;
    color: var(--black-alpha-85);

    .year {
      @include SRegular;
      color: var(--black-alpha-50);
    }

    a {
      cursor: pointer;
    }
  }

  .footer-links-mobile {
    display: none;

    @include mobile-style {
      display: flex;
      flex-direction: column;
      gap: var(--16px);
      @include SMedium;
      color: var(--black-alpha-85);
      margin-top: -4rem;

      .year {
        @include SRegular;
        color: var(--black-alpha-50);
      }

      .links {
        display: flex;
        gap: var(--24px);
      }

      a {
        cursor: pointer;
      }
    }
  }
}

$splash-height: 83vh;

.splash-placeholder {
  height: $splash-height;
  left: 0;
}

.splash {
  position: fixed;
  left: 0;
  height: $splash-height;
  width: 100vw;

  @include mobile-style {
    height: 84.5vh;
  }

  background-color: var(--black-alpha-05);

  .inner-splash {
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 var(--80px);
    position: relative;

    img {
      height: $splash-height;
      width: fit-content;
    }

    @include mobile-style {
      display: none;
    }

    .splash-text {
      height: 100%;
      width: fit-content;

      display: flex;
      flex-direction: column;
      gap: var(--32px);
      justify-content: center;

      h1 {
        @include SuperMegaBold;

        .blue-circle {
          color: var(--sp-primary);
        }
      }

      h2 {
        @include XL4Medium;
      }
    }

    .desk-image {
      display: flex;
      @include mobile-style {
        display: none;
      }
    }
  }

  .inner-splash-mobile {
    display: none;
    @include mobile-style {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;
      height: 100%;
      padding-top: var(--16px);
    }
    // margin: 0 auto;
    // height: 100%;
    // display: flex;
    // justify-content: space-between;
    // flex-direction: row;
    // padding: 0 var(--80px);
    // position: relative;

    img {
      height: $splash-height;
      width: fit-content;
    }

    .splash-text {
      height: 100%;
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: var(--32px);
      justify-content: center;
      align-items: center;

      h1 {
        @include DisplaySemi;

        .blue-circle {
          color: var(--sp-primary);
        }
      }

      h2 {
        @include XL2Medium;
      }
    }

    .mobile-image {
      display: none;
      position: relative;
      z-index: -1;
      @include mobile-style {
        display: flex;
        width: 100%;
        // margin: 50vh auto;
        margin: 0 auto;
        margin-top: var(--32px);
        height: 26rem;
      }
    }
  }
}

.global-mr {
  margin-right: var(--16px);
}

.divider-global {
  height: 90%;
  background-color: var(--black-alpha-10);
  width: 1px;
}

.blue-link {
  color: var(--sp-primary);
}

.desk-only {
  display: flex;
  @include mobile-style {
    display: none !important;
  }
  width: 100%;
}

.desk-only-column {
  display: flex;
  flex-direction: column;
  @include mobile-style {
    display: none !important;
  }
}

.desk-only-no-width {
  display: flex;
  @include mobile-style {
    display: none !important;
  }
}

.tsp {
  background-color: transparent;
}

.burger-nav {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  background-color: var(--white);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  .burg-logo-left {
    position: absolute;
    top: var(--20px);
    left: var(--24px);
  }

  .burg-links {
    display: flex;
    flex-direction: column;
    gap: var(--24px);
    margin-top: var(--164px);
    margin-left: var(--24px);

    a {
      @include XL2Medium;

      &.selected {
        color: var(--sp-primary);
      }
    }
  }

  .bottom-links {
    display: flex;
    flex-direction: column;
    gap: var(--24px);
    padding: var(--24px);

    // height: var(--120px);
    width: 100%;

    color: var(--black);

    a {
      @include SMedium;
    }
  }
}

.mobile-broker-card {
  background-color: var(--black-alpha-03);
  padding: var(--12px) var(--8px);

  .top-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.wallet-img {
  background-color: transparent;
  margin-right: var(--8px);
}

.vert-align {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.markets-list-mobile {
  display: flex;
  flex-direction: column;
  gap: var(--16px);
}
