.meta-display {
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--40px);
  position: relative;

  &.fit-width {
    width: fit-content;
  }

  &.inner-hover {
    background-color: var(--sp-primary-06);
  }

  .stacked {
    display: flex;
    align-items: baseline;
    img {
      &:first-of-type {
        height: var(--32px);
        width: var(--32px);
        border-radius: 9999rem;
      }
    }
    .stacker {
      height: var(--24px);
      width: var(--24px);
      border: var(--2px) solid var(--white);
      position: relative;
      left: calc(var(--16px) * -1);
      border-radius: 9999rem;
      transform: translateY(2px);
    }
  }

  img {
    height: var(--32px);
    width: var(--32px);

    &:first-of-type {
      margin-right: var(--16px);
    }
  }

  .meta-tip {
    position: absolute;
    z-index: 11;
    visibility: hidden;
    border-radius: var(--2px);
    padding: var(--8px) var(--12px);
    background-color: var(--black-alpha-85);
    color: var(--white);
    @include MRegular;
    width: fit-content;
    transition: visibility 10ms ease-in;
    transition: transform 200ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    transform: translateY(-225%);
    margin-left: var(--16px);

    .param {
      .label {
        color: var(--white);
      }

      .value {
        color: var(--white);
      }
    }

    &.show-hover {
      visibility: visible;
    }

    &.top-down {
      top: 100%;
    }

    &.left {
      // transform: translateX(100%);
      left: -300%;
    }
  }

  .info {
    margin-left: var(--16px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .percentage {
      @include LMedium;
    }

    &.stack-adjust {
      margin-left: var(--0px);
      // width: -webkit-fill-available;
    }
  }

  &.hp-con {
    &:hover {
      background-color: var(--sp-primary-06);
    }
  }
}

.big-bal {
  .text {
    display: flex;
    gap: var(--7px);
    align-items: baseline;
    .bal {
      @include XL3Medium;
      color: var(--black-alpha-85);
    }

    .label {
      @include MRegular;
      color: var(--black-alpha-50);
    }
  }

  &.disabled {
    .text {
      .bal {
        color: var(--black-alpha-13);
      }
    }
  }

  &.circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: var(--240px);
    width: var(--240px);
    justify-content: center;
    border-radius: 9999rem;
    position: absolute;
    top: 0;
    pointer-events: none;

    .text {
      flex-direction: column;
      align-items: center;
    }

    &.disabled {
      border: var(--3px) solid var(--black-alpha-13);

      .text {
        .bal {
          color: var(--black-alpha-13);
        }
      }
    }
  }
}

.info-wrapper {
  background-color: var(--sp-primary-06);
  padding: var(--16px) var(--16px);
  border-radius: var(--2px);
  min-height: var(--184px);

  .sp-info-bar {
    display: flex;
    justify-content: space-between;

    .divider {
      height: var(--57px);
      width: var(--2px);
      background-color: var(--black-alpha-10);
      margin-right: 3rem;
    }

    .data-points {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: var(--16px);
      align-items: flex-start;
      gap: var(--48px);

      @include mobile-style {
        gap: var(--24px);
      }

      &.pri {
        flex-grow: 1;

        // .sp-data-point:last-of-type {
        //   .value {
        //     color: var(--u-green);
        //   }
        // }
      }

      &.nowrap {
        flex-wrap: nowrap;
        min-width: fit-content;
      }

      .sp-data-point:not(:last-child) {
        margin-right: 3rem;
      }
    }

    &.clear {
      background-color: transparent;
      padding: 0;

      .data-points {
        display: flex;
        align-items: center;
        margin-left: 1rem;

        .data-points:not(:last-child) {
          margin-right: 1.5rem;
        }
      }
    }

    &.border {
      background-color: transparent;
      border: var(--1px) solid var(--black-alpha-10);
      padding: var(--16px) var(--48px);
    }
  }
}

.thin-lined {
  .label {
    cursor: default;
    @include SRegular;
    color: var(--text-secondary) !important;
    text-decoration: underline;
    text-decoration-style: dotted;
    text-underline-offset: var(--4px);
  }
}

.outer-data-point {
  display: flex;
  align-items: center;
  gap: var(--8px);

  .sp-data-point:not(:last-child) {
    margin-right: 0 !important;
  }

  .color-box {
    margin-right: var(--12px) !important;
  }

  .secondary-value {
    @include XL2Medium;
    color: var(--black-alpha-50);
    align-self: flex-end;
  }
}

.sp-data-point {
  display: flex;
  flex-direction: column;
  gap: var(--4px);

  &.is-horizontal {
    flex-direction: row-reverse;
    gap: var(--8px);
  }

  &.large {
    .label {
      @include LMediumImportant;
    }

    .value {
      @include XL3Medium;
    }
  }

  &.is-right {
    .tip {
      right: 0;
      // transform: translate(-50%, 1rem);
      // transform: translateY(1rem);
      // transition: transform 200ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    }
    .value {
      align-self: flex-end;

      &.risk-red {
        color: var(--u-red);
      }

      &.risk-orange {
        color: var(--u-orange);
      }

      &.risk-green {
        color: var(--u-green);
      }
    }
  }

  .label-wrap {
    display: flex;
    align-items: center;
    gap: var(--6px);

    .label {
      @include SRegular;
      color: var(--black-alpha-50);

      &.light {
        @include LRegular;
      }
    }
  }

  .value {
    @include XL2Medium;
    color: var(--black-alpha-85);

    &.disabled {
      color: var(--black-alpha-20);
    }

    &.only-val {
      @include LMedium;
    }

    &.light {
      @include XL3Regular;
    }
  }

  .sub-value {
    @include LRegular;
    color: var(--black-alpha-50);

    &.positive {
      color: var(--u-green);
    }
  }
}

.hp-con {
  position: relative;

  &:hover {
    .hover-points {
      visibility: visible;
    }
  }
  .hover-points {
    position: absolute;
    z-index: 100;
    visibility: hidden;
    width: var(--300px);
    background-color: var(--white);
    padding: var(--15px) var(--20px);
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.15);
    border-radius: var(--2px);
    border: 1px solid var(--border-border-tertiary, rgba(0, 0, 0, 0.1));
    transform: translate(70%, -15%);
    cursor: default;

    .divider {
      height: var(--1px);
      width: 100%;
      background-color: var(--black-alpha-10);
      margin: var(--12px) 0;
    }

    .hover-param {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: var(--12px);
    }
  }
}

.param-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--16px);

  @include mobile-style {
    margin-top: var(--16px);

    .param {
      .label {
        text-wrap: nowrap;
      }
    }
  }

  &.tight {
    gap: var(--12px);

    .param {
      .label {
        @include SRegular;
      }

      .values {
        .value {
          @include SMedium;
        }
      }
    }
  }

  .param {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tip-svg {
      height: var(--16px);
      width: var(--16px);
    }

    .label {
      @include LRegular;
      color: var(--black-alpha-50);
      margin-right: var(--6px);
    }

    .values {
      display: flex;
      align-items: center;
      gap: var(--8px);
      .value {
        @include LMedium;
        color: var(--black-alpha-85);
        &.risk-red {
          color: var(--u-red);
        }

        &.risk-orange {
          color: var(--u-orange);
        }

        &.risk-green {
          color: var(--u-green);
        }
      }

      .nextVal {
        @include mobile-style {
          text-align: right;
        }
        &.risk-red {
          color: var(--u-red);
        }

        &.risk-orange {
          color: var(--u-orange);
        }

        &.risk-green {
          color: var(--u-green);
        }
      }
    }
  }
}

.info-point {
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;

  &.dis {
    .dark {
      color: var(--black-alpha-13);
    }
    .light {
      color: var(--black-alpha-13);
    }
  }
  .dark {
    @include LMedium;
    color: var(--black-alpha-85);
    display: flex;
    align-items: center;

    &.large-balance {
      @include XL2Medium;
    }
  }

  .slash-value {
    color: var(--black-alpha-50);
  }

  .info-tip {
    position: absolute;
    z-index: 11;
    visibility: hidden;
    border-radius: var(--2px);
    padding: var(--8px) var(--12px);
    background-color: var(--black-alpha-85);
    color: var(--white);
    @include MRegular;
    width: var(--240px);
    transition: visibility 10ms ease-in;
    transition: transform 200ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    transform: translateX(-25%);

    .param {
      .label {
        color: var(--white);
      }

      .value {
        color: var(--white);
      }
    }

    &.show-hover {
      visibility: visible;
    }

    &.top-down {
      top: 100%;
    }

    &.left {
      // transform: translateX(100%);
      left: -300%;
    }
  }

  .light {
    @include MRegular;
    color: var(--black-alpha-50);
    display: flex;

    &.large-balance {
      @include LRegular;
    }

    .secondary-sub {
      display: flex;
      flex-direction: row;
      align-items: center;
      @include MRegular;

      &.hide-secondary {
        @media (max-width: 1250px) {
          display: none !important;
        }
      }
    }
  }

  .with-chev {
    display: flex;
    align-items: center;
    .chev {
      margin-left: var(--7px);
      height: var(--16px);
      width: var(--16px);
    }
  }

  .c-color {
    height: var(--8px);
    width: var(--8px);
    border-radius: 9999rem;
    margin-right: 8px;

    &.undefined {
      display: none;
    }

    &.c-1 {
      background-color: #9224ff;
    }
    &.c-2 {
      background-color: #0fa9a0;
    }
    &.c-3 {
      background-color: #5886cc;
    }
    &.c-4 {
      background-color: #ff6cd6;
    }
    &.c-5 {
      background-color: #b02892;
    }
    &.c-6 {
      background-color: #c7cb0d;
    }
    &.c-7 {
      background-color: #bb5a3b;
    }
    &.c-8 {
      background-color: #551ad3;
    }
    &.c-9 {
      background-color: #1c768a;
    }
    &.c-10 {
      background-color: #be7e00;
    }
    &.c-11 {
      background-color: #00ba61;
    }
    &.c-12 {
      background-color: #9b400d;
    }
    &.c-13 {
      background-color: #ff7f50;
    }
    &.c-14 {
      background-color: #00a3a3;
    }
    &.c-15 {
      background-color: #9f7fff;
    }
    &.c-16 {
      background-color: #8a9a5b;
    }
    &.c-17 {
      background-color: #800020;
    }
  }
}

.marker {
  border-radius: 9999rem;
  height: var(--2px);
  width: var(--2px);
  margin: 0 var(--6px);
  background-color: var(--black-alpha-50);
}

.doughnut-chart-container {
  position: relative;
  height: var(--240px);
  width: var(--240px);
  margin: var(--26px) 0;
}

.point {
  @include XL3Medium;
  color: var(--black-alpha-85);

  &.disabled {
    color: var(--black-alpha-13) !important;
  }

  &.med {
    @include LMedium;
  }
}

.color-box {
  height: var(--12px);
  width: var(--12px);
  margin-right: 1.25rem;
  align-self: center;

  &.blue {
    background-color: var(--sp-primary);
  }

  &.green {
    background-color: var(--u-green);
  }

  &.orange {
    background-color: var(--u-orange);
  }

  &.red {
    background-color: var(--u-red);
  }

  &.black {
    background-color: var(--black-alpha-85);
  }
}

.stacked-global {
  display: flex;
  align-items: baseline;

  &.large {
    img {
      &:first-of-type {
        height: var(--40px) !important;
        width: var(--40px) !important;
        border-radius: 9999rem;
      }
    }
  }
  img {
    &:first-of-type {
      height: var(--32px) !important;
      width: var(--32px) !important;
      border-radius: 9999rem;
    }
  }
  .stacker {
    height: var(--24px) !important;
    width: var(--24px) !important;
    border: var(--2px) solid var(--white);
    position: relative;
    left: calc(var(--12px) * -1);
    border-radius: 9999rem;
    transform: translateY(2px);
  }
}

.simple-meta-display {
  display: flex;
  align-items: center;
  width: fit-content;
  height: var(--40px);
  min-width: 10rem;
  justify-content: space-between;
  padding-right: var(--8px);

  &.no-hover {
    &:hover {
      background-color: transparent !important;
    }
  }

  &:hover {
    background-color: var(--sp-primary-06);
  }

  &.clickable {
    cursor: pointer;
  }

  .simple-meta {
    display: flex;
    align-items: center;
    height: 100%;

    .simple-point {
      @include LMedium;
      justify-self: unset;
    }

    img {
      height: var(--32px);
      width: var(--32px);
    }

    .divider-simple {
      height: 90%;
      background-color: var(--black-alpha-10);
      width: 1px;
      margin-left: var(--16px);
      margin-right: var(--16px);
    }
  }

  .tooltip-simple {
    height: var(--16px) !important;
    width: var(--16px) !important;
  }
}

.info-box {
  padding: var(--8px) 0 var(--8px) var(--12px);
  margin-top: var(--24px);
  border-radius: var(--2px);
  position: relative;
  display: flex;
  align-items: center;

  &.hide {
    display: none;
  }

  &.blue {
    background-color: var(--sp-primary-06);
  }

  &.red {
    background-color: var(--red-muted);
    padding-right: var(--12px);
  }

  &.orange {
    background-color: var(--orange-muted);
    padding-right: var(--12px);
  }

  img {
    margin-right: var(--16px);
  }

  &.no-top {
    margin-top: 0;
  }

  &.full-width {
    width: 100%;
  }

  p {
    @include SRegular;
    color: var(--black-alpha-85);
    width: 95%;
  }

  a {
    @include SMedium;
  }
}
