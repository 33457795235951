.chart-container {
  padding: var(--16px);
  width: 100%;
  min-height: 20rem;

  .points-line {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;

    .cluster-points {
      display: flex;
      align-items: center;
      gap: var(--56px);

      .color-box {
        align-self: center;
      }
    }
  }
}

tspan {
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  color: var(--black-alpha-50);
}

.chart-card-wrapper {
  display: flex;
  flex-direction: column;
  height: var(--368px);
  flex: 1;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);

  @include mobile-style {
    width: 100%;
  }

  .card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include XLMedium;
    padding: var(--20px) var(--16px) var(--20px) var(--24px);
  }

  .inner-chart-card {
    @include mobile-style {
      min-height: var(--400px);
      height: var(--400px);
    }
    height: 100%;
    width: 100%;
  }
}

.label-container {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Euclid Circular B';
  border: 1px solid var(--black-alpha-05);

  .label-row {
    display: flex;
    justify-content: space-between;
    &:not(:first-child) {
      margin-top: -16px;
    }

    .label-value {
      @include MRegular;

      margin-left: var(--8px);
    }

    .label-name {
      @include MMedium;
    }
  }
}

.alt-label-container {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: var(--8px);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Euclid Circular B';
  gap: 16px;
  border: 1px solid var(--black-alpha-05);

  .label-row {
    display: flex;
    justify-content: space-between;

    .label-value {
      @include MRegular;

      margin-left: var(--8px);
    }

    .label-name {
      @include MMedium;
    }
  }
}
