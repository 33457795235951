.reward-banner {
  display: flex;
  align-items: center;
  padding: var(--8px) var(--12px);
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(90deg, rgba(255, 138, 0, 0.1) 0%, rgba(210, 217, 255, 0.3) 100%);
  margin-top: var(--26px);

  .reward-banner__left {
    display: flex;
    align-items: center;
    gap: var(--16px);

    .reward-banner__content {
      display: flex;
      flex-direction: column;

      h3 {
        @include LMedium;
      }
      p {
        @include SRegular;
      }
    }
  }
}

.reward-rate-display {
  display: flex;
  align-items: center;
  gap: var(--4px);
  @include SRegular;

  @include custom-pill-bp {
    flex-direction: column;
    align-items: baseline;
  }

  .plus {
    color: var(--black-alpha-50);
  }

  .pill {
    display: flex;
    align-items: center;
    gap: var(--4px);
    padding: var(--4px);
    background-color: var(--u-green);
    border-radius: 9999rem;
    color: var(--white);

    img {
      height: var(--14px);
      width: var(--14px);
    }
  }

  .time-frame-box {
    display: flex;
    align-items: center;
    gap: var(--4px);

    .clock-icon {
      height: var(--20px);
      width: var(--20px);
      background-color: var(--black-alpha-10);
      border-radius: 9999rem;
    }

    p {
      color: var(--black-alpha-50);
    }
  }
}
