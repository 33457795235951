@mixin SuperMegaBold {
  font-weight: 600;
  font-size: var(--89px);
  line-height: 96%;
  letter-spacing: -3.115px;
}
@mixin MegaRegular {
  font-weight: 400;
  font-size: var(--60px);
  line-height: var(--76px);
}
@mixin DisplaySemiBold {
  font-weight: 600;
  font-size: var(--42px);
  line-height: var(--42px);
}
@mixin DisplaySemi {
  font-weight: 600 !important;
  font-size: var(--42px) !important;
  line-height: var(--42px) !important;
}
@mixin XL4Medium {
  font-weight: 500;
  font-size: var(--32px);
  letter-spacing: -1.12px;
}
@mixin XL3Medium {
  font-weight: 500;
  font-size: var(--30px);
  line-height: var(--32px);
}
@mixin XL3Regular {
  font-weight: 400;
  font-size: var(--30px);
  line-height: var(--32px);
}
@mixin XL2Medium {
  font-size: var(--24px);
  font-weight: 500;
  line-height: var(--32px);
}
@mixin XLMedium {
  font-weight: 500;
  font-size: var(--18px);
  line-height: var(--22px);
}
@mixin XLRegular {
  font-weight: 400;
  font-size: var(--18px);
  line-height: var(--22px);
}
@mixin LRegular {
  font-weight: 400;
  font-size: var(--16px);
  line-height: var(--20px);
}
@mixin LMedium {
  font-weight: 500;
  font-size: var(--16px);
  line-height: var(--20px);
}
@mixin LMediumImportant {
  font-weight: 500 !important;
  font-size: var(--16px) !important;
  line-height: var(--20px) !important;
}
@mixin MRegular {
  font-weight: 400;
  font-size: var(--14px);
  line-height: var(--20px);
}
@mixin MMedium {
  font-weight: 500;
  font-size: var(--14px);
  line-height: var(--20px);
}
@mixin SMedium {
  font-weight: 500;
  font-size: var(--13px);
  line-height: var(--15px);
}
@mixin SRegular {
  font-weight: 400;
  font-size: var(--13px);
  line-height: var(--15px);
}
@mixin SCaps {
  font-weight: 500;
  font-size: var(--11px);
  line-height: var(--20px);
  text-transform: uppercase;
  letter-spacing: var(--1px);
}
@mixin XSRegular {
  font-weight: 400;
  font-size: var(--12px);
  line-height: normal;
}
