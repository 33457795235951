:root {
  --black: #000000;
  --white: #ffffff;

  --sp-primary: #1d41ff;
  --sp-primary-50: rgba(29, 65, 255, 0.5);
  --sp-primary-12: rgba(29, 65, 255, 0.12);
  --sp-primary-06: rgba(29, 65, 255, 0.06);
  --light-solid: #f1f4ff;

  --text-secondary: rgba(0, 0, 0, 0.5);

  --black-alpha-02: rgba(0, 0, 0, 0.02);
  --black-alpha-03: rgba(0, 0, 0, 0.03);
  --black-alpha-05: rgba(0, 0, 0, 0.05);
  --black-alpha-10: rgba(0, 0, 0, 0.1);
  --black-alpha-13: rgba(0, 0, 0, 0.13);
  --black-alpha-15: rgba(0, 0, 0, 0.15);
  --black-alpha-20: rgba(0, 0, 0, 0.2);
  --black-alpha-30: rgba(0, 0, 0, 0.3);
  --black-alpha-50: rgba(0, 0, 0, 0.5);
  --black-alpha-85: rgba(0, 0, 0, 0.85);

  --white-alpha-50: rgba(255, 255, 255, 0.5);
  --white-alpha-75: rgba(255, 255, 255, 0.75);

  --u-green: #00dd5c;
  --u-green-10: rgba(0, 221, 92, 0.1);
  --u-green-2: #5dba02;

  --u-red: #e51d41;
  --red-muted: rgba(255, 46, 0, 0.1);
  --u-red-20: rgba(255, 46, 0, 0.2);
  --u-orange: #ff8a00;
  --u-orange-06: rgba(255, 138, 0, 0.06);

  --u-orange-20: rgba(255, 153, 0, 0.2);
  --orange-muted: rgba(255, 138, 0, 0.1);

  --gradient: radial-gradient(
    273.33% 95.46% at -116.33% 130.17%,
    #00c8ff 0%,
    #1d41ff 42.79%,
    #000000 100%
  );

  --shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);

  --grey-alpha-2: rgba(118, 129, 154, 0.02);
  --grey-alpha-20: rgba(118, 129, 154, 0.2);

  --banner: #3c37ff;
}
