@mixin LargeButton {
  height: var(--36px);
  @include XLMedium;
}

.sp-btn {
  padding: var(--5px) var(--10px) var(--6px) var(--10px);
  border-radius: var(--2px);
  height: var(--30px);
  @include MMedium;
  position: relative;
  white-space: nowrap;

  &.full-w {
    width: 100%;
  }

  &.large {
    @include LargeButton;
  }

  &.rounded {
    border-radius: 9999rem;
  }

  &.primary {
    background-color: var(--sp-primary);
    color: var(--white);

    &:disabled {
      color: var(--black-alpha-20);
      background-color: var(--black-alpha-05);
    }

    &.negative {
      background-color: transparent;
      color: var(--white);
    }
  }

  &.secondary {
    background-color: var(--black-alpha-05);
    color: var(--black-alpha-85);

    &.blue {
      background-color: var(--sp-primary-06);
      color: var(--sp-primary);
    }

    &.flexed {
      display: flex;
      align-items: center;
      gap: var(--8px);
    }
  }

  &.tertiary {
    border: var(--1px) solid var(--black-alpha-85);
    background-color: transparent;
    color: var(--black-alpha-85);

    &:disabled {
      color: var(--black-alpha-20);
      border: none;
      background-color: var(--black-alpha-05);
    }

    &:hover {
      background-color: var(--black-alpha-05);
    }
  }

  &.white {
    background-color: var(--white);
    color: var(--black-alpha-85);

    &.blue-text {
      color: var(--sp-primary);
      white-space: nowrap;
    }
  }

  &.max {
    background-color: var(--sp-primary-06);
    color: var(--sp-primary);

    &.hide {
      visibility: hidden;
    }
  }

  &.sign-in {
    background-color: var(--sp-primary);
    color: var(--white);

    &.large {
      @include LargeButton;
      width: fit-content;
    }
  }

  &.link {
    @include MRegular;
    background-color: transparent;
    height: fit-content;

    &.med {
      @include MMedium;
    }

    &.white {
      color: var(--white);
    }

    &.grey {
      color: var(--black-alpha-50);
    }

    &.pri {
      color: var(--sp-primary);
    }

    &.black {
      color: var(--black-alpha-85);
    }
  }

  &.no-style {
    background-color: transparent;
    padding: unset;
    height: unset;
  }

  &.refresh {
    @include LargeButton;
    background-color: var(--black-alpha-03);
    color: var(--black-alpha-50);
    padding: var(--4px) var(--8px) var(--4px) var(--12px);
    display: flex;
    align-items: center;
    gap: var(--8px);
    position: relative;
    overflow: hidden;
    z-index: 1;

    .refresher-animation {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 120%;
      background-color: var(--white);
      transition: width 1s linear;
      z-index: 2;

      &:hover {
        visibility: hidden;
      }
    }

    &:hover {
      background-color: var(--black-alpha-05);
      color: var(--black-alpha-85);
      z-index: 3;
    }

    .content {
      position: relative;
      z-index: 3;
      height: 100%;
      display: flex;
      align-items: center;

      &.arrow {
        height: var(--22px);
        width: var(--22px);
      }

      &:hover {
        z-index: 4;
      }

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }

      &.spin-animation {
        animation: spin 0.5s ease-in-out;
      }
    }
  }
}

.a-link {
  @include MRegular;
  background-color: transparent;
  height: fit-content;

  &.bg-blue {
    background-color: var(--sp-primary-06);
    border-radius: 9999rem;
  }

  &.bg-grey-select {
    background-color: transparent;
    @include MMedium;
    padding: var(--4px) var(--12px);

    &.selected {
      background-color: var(--black-alpha-05);
      border-radius: 9999rem;
    }

    &:hover {
      background-color: var(--black-alpha-05);
      border-radius: 9999rem;
      color: var(--sp-primary);
    }
  }

  &.white {
    color: var(--white);
  }

  &.grey {
    color: var(--black-alpha-50);
  }

  &.pri {
    color: var(--sp-primary);
  }

  &.black {
    color: var(--black-alpha-85);
  }
}

.more-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .line {
    width: 100%;
    background-color: var(--black-alpha-10);
    height: 1px;
  }
}

.full-w-global {
  width: 100%;
}

.btn-tip {
  position: absolute;
  z-index: 11;
  visibility: hidden;
  border-radius: var(--2px);
  padding: var(--8px) var(--12px);
  background-color: var(--black-alpha-85);
  color: var(--white);
  @include MRegular;
  width: fit-content;
  transition: visibility 10ms ease-in;
  transition: transform 200ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  transform: translate(-15%, -225%);
  // transform: translateX(-25%);

  .param {
    .label {
      color: var(--white);
    }

    .value {
      color: var(--white);
    }
  }

  &.show-hover {
    visibility: visible;
  }

  &.top-down {
    top: 100%;
  }

  &.left {
    // transform: translateX(100%);
    left: -300%;
  }
}
