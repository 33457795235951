@mixin hide-scrollbar {
  // For Webkit-based browsers
  &::-webkit-scrollbar {
    display: none;
  }

  // For Firefox
  scrollbar-width: none;

  // Optional: For IE and Edge
  -ms-overflow-style: none;
}

.broker-details {
  display: flex;
  flex-direction: column;
  gap: var(--24px);
  padding-bottom: var(--80px);
  width: 100vw;
  background-color: var(--white);
  position: fixed;
  top: 0;
  left: 0;

  .top-details {
    margin-bottom: var(--24px);
    padding: var(--18px) var(--24px) var(--8px) var(--16px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    justify-content: center;

    .flexer-max {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: var(--1488px);
    }

    &.sticky {
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    }

    .back-split {
      display: flex;
      align-items: center;
      gap: var(--24px);
      width: fit-content;

      .back-chev {
        padding: var(--32px) var(--16px) var(--32px) var(--32px);
        margin-left: calc(var(--24px) * -1);
        cursor: pointer;
        position: fixed;
      }
    }

    .data-split {
      display: flex;
      align-items: center;
      gap: var(--48px);
    }
  }

  .bottom-details {
    display: flex;
    gap: var(--24px);
    margin-top: var(--90px);

    .left-details {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: var(--24px);
      height: calc(100vh - 100px);
      overflow-y: auto;
      @include hide-scrollbar;

      &.extra-margin {
        padding-bottom: var(--48px);
      }
    }

    .right-details {
      width: var(--428px);
      display: flex;
      flex-direction: column;
      gap: var(--24px);

      .fees-container {
        padding: var(--24px) var(--16px);
        background-color: var(--black-alpha-03);

        .param {
          margin-bottom: var(--8px);
          &:last-child {
            margin-bottom: 0;
          }
          .label {
            color: var(--black-alpha-85);
            @include LRegular;
          }

          .value {
            color: var(--black-alpha-85);
            @include LMedium;
          }
        }
      }
    }
  }

  .details-box {
    padding: var(--24px) var(--16px);

    &.short {
      padding: var(--16px) var(--16px);
    }

    &.shadow {
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
      border: none;
    }
    display: flex;
    flex-direction: column;
    gap: var(--20px);
    border-radius: var(--4px);
    border: 1px solid var(--black-alpha-10);

    .top-label {
      @include XLMedium;
    }

    .label-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include LMedium;
      height: var(--48px);
      border-radius: 2px;
      padding: var(--10px);

      &:hover {
        background-color: var(--black-alpha-02);
      }

      &.simple {
        &:hover {
          background-color: transparent;
        }
      }

      .broker-buttons {
        display: flex;
        gap: var(--8px);
      }

      .split {
        display: flex;

        .light {
          color: var(--black-alpha-50);
        }
      }
    }
  }
}

.time-tabs {
  display: flex;
  gap: var(--8px);
  align-items: center;

  button {
    @include SMedium;
    padding: var(--3px) var(--6px);
    color: var(--black-alpha-50);
    background-color: transparent;
    height: var(--20px);
    width: var(--17px);
  }

  button.isActive {
    color: var(--black);
    background-color: var(--black-alpha-05);
    border-radius: var(--2px);
  }
}

.basic-meta {
  display: flex;
  align-items: center;
  gap: var(--12px);
  margin-left: var(--48px);

  &.small {
    img {
      height: var(--32px);
      width: var(--32px);
    }

    .name {
      @include LMedium;
    }

    .full-name {
      @include MRegular;
      color: var(--black-alpha-50);
    }

    margin: 0;
    max-width: 60%;
  }

  img {
    height: var(--48px);
    width: var(--48px);
  }

  .name {
    @include XL2Medium;
  }

  .full-name {
    @include LRegular;
    color: var(--black-alpha-50);
  }
}
