.broker-list {
  margin-top: var(--26px);
  width: 100%;

  .headings {
    margin-bottom: var(--24px);

    p {
      @include MRegular;
      color: var(--black-alpha-50);
    }
  }

  .headings-bal {
    margin-bottom: var(--24px);
    width: 50%;

    p {
      @include MRegular;
      color: var(--black-alpha-50);
      white-space: nowrap;
    }
  }

  .inner {
    display: flex;
    align-items: center;
    width: 90%;
  }

  .inner-bal {
    display: flex;
    align-items: center;
    width: 50%;
  }

  .rows {
    display: flex;
    flex-direction: column;
    gap: var(--26px);
    .collateral-row {
      display: flex;
      align-items: center;
      width: 100%;
      height: var(--48px);

      margin-left: var(--8px);

      &:hover {
        background-color: var(--black-alpha-02);
        .tip-box {
          &.c-row {
            visibility: visible;
          }
        }
      }

      .flasher {
        display: flex;
        height: 2rem;
        // margin-top: 4rem;
        width: calc(90% - 0.5rem);
        max-width: 60vw;

        // Assuming .column class for each column
        .column {
          height: 2rem;
          display: flex;
          align-items: center;

          .dummy-meta {
            display: flex;
            align-items: center;
            min-width: 4rem;
            width: fit-content;

            .stacked {
              height: 100%;
              display: flex;
              align-items: baseline;
              .circ1 {
                height: var(--32px);
                width: var(--32px);
                border-radius: 9999rem;
                animation: flashing 1s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
              }
              .stacker {
                height: var(--24px);
                width: var(--24px);
                border: var(--2px) solid var(--white);
                position: relative;
                left: calc(var(--16px) * -1);
                border-radius: 9999rem;
                transform: translateY(2px);
                animation: flashing 1s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
              }
            }
          }

          .dummy-basic {
            height: 50%;
            width: 3rem;
            border-radius: 0.25rem;
            animation: flashing 1s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
          }

          .dummy-double {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: var(--8px);

            .topper {
              height: 50%;
              width: 4rem;
              border-radius: 0.25rem;
              animation: flashing 1s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
            }

            .bottomer {
              height: 30%;
              width: 3rem;
              border-radius: 0.25rem;
              animation: flashing 1s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
            }
          }

          .dummy-info-point {
            display: flex;
            flex-direction: column;
            height: 100%;

            .dark {
              height: 90%;
              width: 4rem;
              animation: flashing 1s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
              margin-bottom: 0.5rem;
              border-radius: 0.25rem;
            }

            .light {
              height: 60%;
              display: flex;
              align-items: center;
              .primary-sub {
                height: 100%;
                width: 2rem;
                animation: flashing 1s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
                border-radius: 0.25rem;
              }
              .marker {
                border-radius: 9999rem;
                height: var(--2px);
                width: var(--2px);
                margin: 0 var(--6px);
                background-color: var(--black-alpha-20);
                animation: flashing 1s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
              }
              .secondary-sub {
                height: 100%;
                width: 3rem;
                animation: flashing 1s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
                border-radius: 0.25rem;
              }
            }
          }
        }
      }
      .dummy-action-circles {
        display: flex;
        align-items: center;
        margin-left: 1.5rem;
        height: 100%;
        .c {
          height: var(--32px);
          width: var(--32px);
          border-radius: 9999rem;
          animation: flashing 1s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;

          &:first-of-type {
            margin-right: var(--6px);
            animation: flashing 1s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
          }
        }
      }

      .balance-buttons {
        display: flex;
        justify-content: flex-end;
        width: 50%;
        button:last-of-type {
          margin-left: var(--2px);
        }
      }
    }
  }
}

.broker-buttons {
  display: flex;
  justify-content: flex-end;
  width: 10%;
  margin-right: var(--10px);

  button:last-of-type {
    margin-left: var(--8px);
  }

  @include mobile-style {
    height: var(--32px);
    width: var(--32px);
    margin-right: 0;
  }
}

.balances-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--16px);
}

.balance-buttons {
  button:last-of-type {
    margin-left: var(--8px);
  }
}

@keyframes flashing {
  0%,
  100% {
    background-color: var(--black-alpha-20);
  }
  10%,
  90% {
    background-color: var(--black-alpha-10);
  }
  20%,
  80% {
    background-color: var(--black-alpha-05);
  }
  30%,
  70% {
    background-color: var(--black-alpha-03);
  }
  40%,
  60% {
    background-color: var(--black-alpha-02);
  }
  50% {
    background-color: var(--white);
  }
}

.multiply {
  display: flex;
  flex-direction: column;
  gap: var(--26px);
  .title {
    width: 100%;
    text-align: center;
    @include XL3Medium;
    color: var(--black);
    padding-top: var(--26px);
  }

  .subtitle {
    @include XLMedium;
  }

  .multi-card-list {
    display: flex;
    flex-direction: column;
    gap: var(--26px);

    .multi-card {
      display: flex;
      flex-direction: column;
      padding: var(--20px);
      border-radius: var(--2px);
      gap: var(--16px);
      box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
      position: relative;

      .multi-card-over {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .over-left {
          display: flex;
          align-items: center;
          gap: var(--12px);
          width: 50%;

          .apy-preview {
            background-color: var(--u-orange);
            color: var(--white);
            @include LMedium;
            border-radius: 9999rem;
            padding: var(--4px) var(--12px);

            .bigger {
              @include XL2Medium;
            }
          }

          .logos {
            display: flex;
            align-items: center;
            gap: var(--8px);
            color: var(--black-alpha-20);
            font-size: var(--24px);

            img {
              background-color: var(--light-solid);
              padding: var(--4px) var(--12px);
              border-radius: 9999rem;
            }
          }
        }

        .over-right {
          width: 50%;

          .over-details {
            @include XLMedium;
            width: 100%;

            a {
              @include MRegular;
              color: var(--sp-primary);
            }

            .split {
              display: flex;
              align-items: center;
              // justify-content: space-between;
              gap: var(--16px);
            }

            .right-details {
              @include SRegular;
              // float: right;
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .under-details {
        text-align: right;
        @include MMedium;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        // width: 40%;
      }

      .multi-card-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .multi-card-meta {
          display: flex;
          align-items: center;
          .logo {
            height: var(--32px);
            width: var(--32px);
          }
          .logo:first-of-type {
            margin-right: var(--8px);
          }
          .logo:last-of-type {
            margin-left: var(--8px);
          }
          p {
            @include LMedium;
            margin-left: var(--16px);
          }
        }
      }
      .multi-card-columns {
        display: flex;
        flex-direction: column;
        gap: var(--16px);
        position: relative;

        .multi-card-headers {
          position: relative;
          z-index: 2;
          &.long {
            width: 90%;
          }
          width: 35%;
          display: flex;
          align-items: center;
          @include SRegular;
          color: var(--black-alpha-50);
          justify-content: space-between;

          p {
            flex: 1 1 calc(25% - 1em);
          }
        }
        .multi-card-row {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: var(--48px);
          position: relative;
          z-index: 2;

          &.empty {
            .left-col-2 {
              color: var(--black-alpha-20);
            }
          }

          .row-left {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 35%;
            &.long {
              width: 90%;
            }

            img {
              height: var(--32px);
              width: var(--32px);
            }

            .left-col {
              flex: 1 1 calc(25% - 1em);
              @include LMedium;
            }

            .left-col-2 {
              flex: 1 1 calc(25% - 1em);
              @include LMedium;
            }
          }
        }
      }
    }
  }
}

.multi-position {
  position: relative;
  z-index: 2;
}

.blue-bg {
  position: absolute;
  z-index: 1;
  height: var(--120px);
  width: 69%;
  background-color: var(--sp-primary-06);
  bottom: -1.25rem;
  right: -1.25rem;
}
