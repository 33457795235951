.sp-tabs {
  display: flex;
  align-items: center;

  &.full-width {
    width: 100%;

    button {
      width: 100%;
    }

    button:not(:last-of-type) {
      margin-right: var(--2px);
    }
  }

  &.basic {
    button {
      @include LargeButton;
      background-color: var(--black-alpha-05);
      padding: var(--6px) var(--12px) var(--7px);
      color: var(--black-alpha-85);
      border-radius: var(--2px);
      white-space: nowrap;

      &.active {
        color: var(--white);
        background-color: var(--black-alpha-85) !important;
      }
    }

    button:not(:last-of-type) {
      margin-right: var(--2px);
    }

    button:hover {
      background-color: var(--black-alpha-10);
    }

    .divider {
      width: var(--1px);
      height: var(--35px);
      background: var(--black-alpha-10);
      border-radius: var(--1px);
      margin-left: var(--24px);
      margin-right: var(--24px);
    }

    &.rounded {
      button {
        border-radius: 9999rem;
        margin-right: var(--16px);
      }
    }
  }

  &.tab-link {
    height: var(--30px);
    @include XLMedium;
    gap: var(--24px);

    .regular {
      padding-bottom: var(--8px);
      border-bottom: 1.5px solid transparent;
      color: var(--black-alpha-50);
      background-color: transparent;
    }

    .active {
      color: var(--black-alpha-85);
      border-bottom: 1.5px solid var(--sp-primary);
    }
  }
}
