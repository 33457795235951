.search-container {
  height: var(--20px);
  min-width: var(--300px);
  display: flex;
  align-items: center;
  margin-top: var(--26px);
  @include LRegular;

  @include mobile-style {
    min-width: var(--100px);
  }

  &.no-margin {
    margin-top: 0;
  }

  &.is-baseline {
    align-self: baseline;
  }

  img {
    height: var(--24px);
    width: var(--24px);
  }

  input {
    background-color: transparent;
    padding-left: var(--8px);
    width: 100%;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--black-alpha-50);
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--black-alpha-50);
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--black-alpha-50);
    }
  }
}
