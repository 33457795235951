body {
  margin: 0;
  font-family: 'Euclid Circular B', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@mixin mobile-style {
  @media (max-width: 740px) {
    @content;
  }
}

@mixin desktop-style {
  @media (min-width: 740px) {
    @content;
  }
}

.mobile-only {
  display: none !important;
  @include mobile-style {
    display: flex !important;
  }
}

@mixin custom-pill-bp {
  @media (max-width: 1300px) {
    @content;
  }
}

#App {
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: var(--black-alpha-85);

  @include mobile-style {
    overflow-x: hidden;
  }
}

.mobile-layover {
  display: none;
  background: var(--gradient);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    gap: var(--24px);
    align-items: center;
    justify-content: center;
    color: var(--white);
    text-align: center;
    h2 {
      @include XL3Medium;
    }
  }
}

@mixin resetStyles() {
  * {
    font-weight: inherit;
    font-family: inherit;
    font-style: inherit;
    font-size: 100%;
    border: 0 none;
    outline: 0;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
}
@include resetStyles();

button,
a {
  cursor: pointer;
}

button {
  &:disabled {
    cursor: default;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.web3-buffer {
  margin-right: var(--16px);
}

.basic-link {
  color: var(--sp-primary);
  @include SMedium;
}
