.tx-form {
  background-color: var(--white);
  padding: var(--20px);
  display: flex;
  flex-direction: column;
  gap: var(--20px);
  min-height: fit-content;
  width: var(--480px);
  align-items: center;
  position: static;
  border-radius: var(--2px);
  box-shadow: var(--0px) var(--10px) var(--40px) var(--black-alpha-10);

  &.yellow-zone {
    input {
      color: var(--u-orange) !important;
    }
    .send-btn {
      background-color: var(--u-orange);
      color: var(--white);
    }
  }

  &.red-zone {
    input {
      color: var(--u-red) !important;
    }
    .send-btn {
      background-color: var(--u-red-20);
      color: var(--white);
    }
  }

  &.green-zone {
    input {
      color: var(--u-green) !important;
    }
    .send-btn {
      background-color: var(--u-green);
      color: var(--white);
    }
  }

  .m-title {
    @include XLMedium;
    text-align: center;
  }

  .title {
    display: flex;
    align-items: center;

    .info-point {
      align-items: center;
    }

    .logo {
      margin-right: var(--16px);
      height: var(--30px);
      width: var(--30px);
    }
  }

  .amount-box {
    padding: var(--24px) 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .slip-details {
      align-self: flex-end;
      @include SRegular;
      color: var(--black-alpha-50);
    }
  }

  .slip-check {
    @include SMedium;
    color: var(--black-alpha-50);
  }

  .review-buttons {
    display: flex;
    align-items: center;
    gap: var(--16px);
    width: 100%;

    button {
      width: 100%;
    }
  }

  .input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--0px);
    border-radius: var(--2px);
    width: 100%;
    height: fit-content;
    margin-bottom: var(--4px);
    margin-top: var(--4px);

    img {
      height: var(--40px);
      width: var(--40px);
    }

    .center {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      height: calc(100% - var(--8px));

      .i-label {
        @include XLMedium;
        color: var(--black-alpha-50);
        transform: translateY(var(--4px));

        .dark {
          color: var(--black-alpha-85);
        }

        .error {
          color: var(--u-red);
        }
      }

      .form-input {
        @include MegaRegular;
        color: var(--black-alpha-85);
        width: 95%;
        text-align: center;

        &.small {
          font-size: var(--44px);
        }

        &.x-small {
          font-size: var(--32px);
        }

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: var(--black-alpha-13);
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: var(--black-alpha-13);
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: var(--black-alpha-13);
        }
      }

      .value {
        @include LRegular;
        color: var(--black-alpha-50);
        transform: translateY(calc(var(--4px) * -1));
      }
    }
  }

  .balances {
    color: var(--black-alpha-50);
    .dark {
      @include LMedium;
    }
    .light {
      @include LRegular;
    }
  }

  .t_c {
    @include SRegular;
    color: var(--black-alpha-50);
  }
}

.slider-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.slider-container p {
  font-size: 16px;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 5px;
  background: var(--black-alpha-03);
  outline: none;
  opacity: 1;
  transition: opacity 0.2s;
  margin-bottom: 32px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: black;
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid white;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: black;
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid white;
}

.slider-track {
  position: absolute;
  top: 50%;
  left: 0;
  height: 5px;
  background: black;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 1;
}

.slider-value {
  position: absolute;
  top: 40px;
  transform: translateX(-50%);
  font-weight: bold;
  background: transparent;
  font-size: 16px;
}

.slippage-menu {
  padding: var(--12px);
  width: 100%;
  background-color: var(--black-alpha-03);

  .slippage-input {
    width: 100%;
    display: flex;
    align-items: center;

    .sp-tabs {
      gap: var(--12px);
      margin-right: var(--12px);
    }

    .slip-input {
      padding: var(--8px);
      width: 100%;
      border: 1px solid var(--black-alpha-20);

      background-color: var(--black-alpha-05);
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      @include LMedium;
      input {
        background-color: transparent;
      }
    }
  }

  .slip-info {
    @include SRegular;
    color: var(--black-alpha-50);
    margin-top: var(--16px);
  }
}
