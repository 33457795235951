.seg-label {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: transparent;
  gap: var(--4px);

  @include mobile-style {
    transform: translate(0, 2rem) !important;
  }

  &.center {
    text-align: center;
    align-items: center;
    position: absolute;
    transform: translate(0rem, 3rem);
  }

  &.push-right {
    transform: translate(25%, 3rem);

    &.big {
      transform: translate(45%, 3rem);
    }
  }

  .values {
    display: flex;
    align-items: flex-end;
    gap: var(--8px);
    @include XL2Medium;

    .light-value {
      color: var(--black-alpha-50);
    }

    .xtra-light-value {
      color: var(--black-alpha-20);
    }
  }

  .label {
    @include SRegular;
    color: var(--black-alpha-50);

    &.tip-line {
      text-decoration: underline;
      text-decoration-style: dotted;
      text-underline-offset: var(--4px);
    }
  }
  .tip-shift {
    left: -35%;
  }
}

.no-wrap {
  white-space: nowrap;
  cursor: default;
}
